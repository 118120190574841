import React, { useEffect, useState } from 'react';
import { globalStore } from '~/store';
import { getHash } from '~/utils';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
const WebRTCData: React.FC = () => {
  const [webRTCData, setWebRTCData] = useState<any>(null);

  const collectWebRTCData = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const devicesInfo = devices.map((device) => ({
        kind: device.kind,
        label: device.label,
        deviceId: device.deviceId,
        groupId: device.groupId,
      }));

      const pc = new RTCPeerConnection({
        iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
      });

      pc.createDataChannel('test');

      const offer = await pc.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      });
      await pc.setLocalDescription(offer);

      const candidates: string[] = [];

      pc.onicecandidate = (event) => {
        if (event.candidate) {
          candidates.push(event.candidate.candidate);
        }
      };

      await new Promise((resolve) => {
        pc.onicegatheringstatechange = async () => {
          if (pc.iceGatheringState === 'complete') {
            const sdp = pc.localDescription ? pc.localDescription.sdp : '';
            candidates.push(sdp);
            resolve(null);
          }
        };
      });

      const fullSdpLog = JSON.stringify(candidates);

      const webRTCInfo = {
        mediaDevices: devicesInfo,
        sdpLog: JSON.parse(fullSdpLog),
      };

      const hash = await getHash(webRTCInfo);

      const webRTCDataInfo = {
        hash: hash,
        data: webRTCInfo,
      };

      globalStore.set({ ...globalStore.get(), webrtc: { hash: hash, data: webRTCDataInfo } });
      setWebRTCData(webRTCDataInfo);
    } catch (error) {
      console.error('Error collecting WebRTC data:', error);
    }
  };

  useEffect(() => {
    collectWebRTCData();
  }, []);

  return (
    <div>
      <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">
        WebRTC <span className="text-sm border px-1">{webRTCData?.hash.slice(0, 12)}</span>
      </h2>
      <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
        <JsonView src={webRTCData} />
      </div>
    </div>
  );
};

export default WebRTCData;
